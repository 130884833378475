.chatRoom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(145deg, rgb(255, 187, 0) 10%, rgb(65, 53, 3) 20%, rgb(224, 200, 127) 40%, rgb(100, 82, 0) 60%, rgb(155, 108, 20) 80%, rgb(255, 155, 0) 100%);
    opacity: 0.98;
    background-size: 400% 400%;
    animation: shimmerBG 10s ease infinite;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), inset 0 1px 3px 0 rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border: 1px solid #c9b037;
    color: #070707; /* Dark text color for contrast */
    position: relative;
    top: 0px;
}
@keyframes shimmerBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
.chatMessages {
    height: 400px;
    overflow-y: auto;
    border-bottom: 1px solid #ff292900;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
@media screen and (max-width: 768px) {
    .chatMessages {
        height: 300px;
        margin-bottom: 110px;
        padding-bottom: 0px;

    }
}

.message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    background: #f3f3f3;
    border-radius: 4px;
}

.messageContent {
    flex-grow: 1;
    /* If you want some space between the message content and timestamp */
    margin-right: 10px; 
}

.messageTimestamp {
    font-size: 0.6em;
    color: #444343;
    margin-left: 0px;
    text-align: center;
}
@media screen and (max-width: 768px) {
    .messageTimestamp {
        font-size: 0.3em;
    }
}

.messageTime {
    font-weight: bold;
    font-size: 1.5em;
    color: #056b63;
}
@media screen and (max-width: 768px) {
    .messageTime {
        font-size: 1.8em;
    }
}

.messageSender {
    font-weight: bold;
    color: #2601fa;
}

.messageText {
    display: block;
    margin-top: 5px;
    color: #000000;
    font-weight: bolder;
}

.chatInput {
    display: flex;
}
@media screen and (max-width: 768px) {
    .chatInput {
        flex-direction: column; /* Display elements vertically */
        align-items: center; /* Center elements horizontally */
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10; /* Make sure it does not overlap */
    }

    .chatInput input[type="text"] {
        width: calc(100% - 20px);
        margin-bottom: 10;
        padding: 10px;
    }
}

/* Style the buttons container to keep buttons horizontal */
.buttonsContainer {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.chatInput input {
    flex: 1; /* Takes up the remaining space */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
}

.chatInput button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    width: 100px; /* Fixed width for the button */
}

.chat-input button:hover {
    background-color: #0056b3;
}

.usernameInput {
    display: flex;
    margin-top: 20px;
}

.usernameInput input {
    flex: 2;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
}

.usernameInput button {
    flex: 1;
    padding: 8px 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.usernameInput button:hover {
    background-color: #218838;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
    .usernameInput {
        flex-direction: column; /* Stack input and button vertically on mobile */
    }

    .usernameInput input {
        position: fixed;
        flex: none; /* Reset the flex value to control width separately */
        width: 90%; /* Make the input field full width on mobile */
        right: 10px; /* Remove margin on mobile */
        bottom: 250px;
        text-align: center;
    }

    .usernameInput button {
        position: fixed;
        flex: none; /* Reset the flex value to control width separately */
        width: 50%; /* Make the button full width on mobile */
        bottom: 210px; /* Add margin between input and button on mobile */
        right: 108px; /* Remove margin on mobile */
    }
}

.scrollToBottom {
    position: absolute;
    right: 50vw; /* Adjust this percentage to position it horizontally */
    bottom: 10vh; /* Adjust this percentage to position it vertically */
    color: rgb(7, 3, 3);
    border: none;
    max-width: 100px;
    cursor: pointer;
    font-size: 25px;
    font-weight: bolder;
    display: none;
}

@media (max-width: 768px) {
    .scrollToBottom {
        right: 5vw; /* Adjust for smaller screens */
        bottom: 5vh; /* Adjust for smaller screens */
        font-weight: bolder;
        display: none; /* Change to 'none' if you want to hide on small screens */
        padding: 50px;
    }
}

.scrollToBottom:hover {
    background-color: #0056b3;
}
@media (max-width: 768px) {
    .scrollToBottom:hover {
        background-color: #0056b3;
    }
}

.scrollToBottom::selection {
    background: transparent;
}
@media (max-width: 768px) {
    .scrollToBottom::selection {
        background: transparent;
    }
}

.chatInputContainer {
    display: flex;
    position: relative;
    align-items: center;
}

.daoLogo {
    position: fixed;
    bottom: 15px;
    right: 320px;
    max-width: auto;
    max-height: 40px;
}
@media (max-width: 768px) {
    .daoLogo {
        bottom: 65px;
        right: 25px;
        max-width: auto;
        max-height: 30px;
    }
}

.chatInputContainer input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
}

.emojiPicker {
    transform: scale(0.8); /* Adjust the scale as needed */
    transform-origin: bottom right; /* Adjust as needed */
    /* Your existing styles */
    position: absolute;
    bottom: 55px;
    right: 20px;
    z-index: 100;
}
@media (max-width: 768px) {
    .emojiPicker {
        transform: scale(0.8); /* Adjust the scale as needed */
        transform-origin: bottom right; /* Adjust as needed */
        /* Your existing styles */
        position: absolute;
        bottom: 110px;
        right: 65px;
        z-index: 100;
    }
}

  /* Style the emoji picker emojis */
  .emoji-picker .emoji {
    font-size: 12px;
    cursor: pointer;
    padding: 0px;
  }
  @media (max-width: 768px) {
    .emoji-picker .emoji {
      font-size: 14px;
    }
  }
  
  /* Style the emoji picker emojis on hover */
  .emoji-picker .emoji:hover {
    background-color: #f2f2f2;
  }
  @media (max-width: 768px) {
    .emoji-picker .emoji:hover {
      background-color: transparent;
    }
  }

  .customButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 24px;
    box-shadow: none;
}

.customButton::selection {
    background: transparent;
}

.walletInfo {
    position: relative;
    align-items: right;
    bottom: 120px;
    left: 0px;
}

.epr-header {
    display: none;
}
.Flex.epr-preview.FlexRow {
    display: none;
}


@media (max-width: 768px) {
    .epr-emoji-category.epr-hidden-on-search {
        display: none;
    }
}

