.main {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed; /* This will keep the background fixed */
  }
  .goldBackground {
    background-size: contain; /* Cover the entire area */
    background-repeat: no-repeat;
    background-position: relative;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #070707;
    color: #fff; /* Text color */
  }
  
  .contentContainer {
    max-width: 1800px;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(145deg, rgb(255, 187, 0) 10%, rgb(65, 53, 3) 20%, rgb(224, 200, 127) 40%, rgb(100, 82, 0) 60%, rgb(155, 108, 20) 80%, rgb(255, 155, 0) 100%);
    opacity: 0.98;
    background-size: 400% 400%;
    animation: gradientBG 10s ease infinite;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), inset 0 1px 3px 0 rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border: 1px solid #c9b037;
    color: #070707; /* Dark text color for contrast */

  }
  
  /* Animation for the gradient background */
  @keyframes gradientBG {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
    color: #331d01;
    text-shadow: 0px 2.5px 1.5px rgba(131, 75, 1, 0.6);
    font-weight: bold;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: rgba(31, 18, 1, 0.842);
    text-shadow: 0px 2.5px 1.5px rgba(131, 75, 1, 0.6);
    font-weight: bold;;
  }

  .linkContainer {
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Center the links horizontally */
    gap: 20px; /* Space between the links */
  }
  
  /* Styles for individual links */
  .daoLink, .chatLink {
    background-color: #ffd900b0; /* Base gold color */
  background-image: linear-gradient(45deg, #725b08 5%, #caae0d 40%, #ffd000 60%, #eee092 100%);
  color: #3b2c02; /* Set text color to white */
  border: 1px solid #c9b037; /* Gold border */
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), /* Main shadow */
              inset 0 -1px 1px rgba(255, 255, 255, 0.3), /* Bottom highlight */
              inset 0 1px 1px rgba(0, 0, 0, 0.4); /* Top inner shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Sparkling effect on hover */
  &:hover {
    background-image: linear-gradient(45deg, #fffbd5, #ffdf00, #fffbd5);
    box-shadow: 0 0 15px #ffdf00; /* Brighter shadow to create a sparkle */
  }
}

  
  .daoLink:hover, .chatLink:hover {
    background-color: rgb(252, 151, 0); /* Change color on hover */
  }
  /* GPHX Balance section */
  h2 {
    font-size: 18px;
    margin-top: 20px;
    color: #331d01;
    text-shadow: 0px 2px 1px rgba(131, 75, 1, 0.6);
  }
  
  
  /* Unstake Tokens Section */
  input[type="text"],
  button {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 16px;
  }
  
  button {
    background-color: #ffd900b0; /* Base gold color */
    background-image: linear-gradient(45deg, #725b08 5%, #caae0d 40%, #b19412 60%, #eee092 100%);
    color: rgb(56, 39, 1)fff; /* Set text color to white */
    border: 1px solid #c9b037; /* Gold border */
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), /* Main shadow */
                inset 0 -1px 1px rgba(255, 255, 255, 0.3), /* Bottom highlight */
                inset 0 1px 1px rgba(0, 0, 0, 0.4); /* Top inner shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    /* Sparkling effect on hover */
    &:hover {
      background-image: linear-gradient(45deg, #fffbd5, #ffdf00, #fffbd5);
      box-shadow: 0 0 15px #ffdf00; /* Brighter shadow to create a sparkle */
    }
  }
  
  button:active {
    background-color: #ffd700; /* Solid gold color for active state */
    box-shadow: 0 0 10px #ffdf00; /* Sparkle effect for active state */
  } /* Darker blue on hover */
  
  
  /* Admin Panel section (if applicable) */
  .adminPanel {
    /* Add styles for admin panel components here */
  }
  
  .filterButtons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filterButton {
    background-color: #ffd900b0; /* Base gold color */
    background-image: linear-gradient(45deg, #725b08 5%, #caae0d 40%, #ffd000 60%, #eee092 100%);
    color: #ffbc04; /* Set text color to white */
    border: 1px solid #c9b037; /* Gold border */
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), /* Main shadow */
                inset 0 -1px 1px rgba(255, 255, 255, 0.3), /* Bottom highlight */
                inset 0 1px 1px rgba(0, 0, 0, 0.4); /* Top inner shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  
    /* Sparkling effect on hover */
    &:hover {
      background-image: linear-gradient(45deg, #fffbd5, #ffdf00, #fffbd5);
      box-shadow: 0 0 15px #ffdf00; /* Brighter shadow to create a sparkle */
    }
  }
  
  .filterButton.active {
    background-color: #ffd700; /* Solid gold color for active state */
    box-shadow: 0 0 10px #ffdf00; /* Sparkle effect for active state */
  }
  
  .homePage {
    /* Styles for the background image */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* Ensure it covers the entire viewport or desired area */
    min-height: 100vh; 
    display: flex;
    align-items: center; /* Align content vertically */
    justify-content: center; /* Align content horizontally */
  }
  
  .contentContainer {
    text-align: center; /* Center the text and button */
    padding: 10px; /* Add some padding */
    background-color: rgba(206, 141, 2, 0.9); /* Optional: semi-transparent background for better readability */
    border-radius: 10px; /* Optional: rounded corners */
    /* Add more styling as needed */
  }
  
  .homePage h1 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 30px;
    color: #000000;
  }
  
  .homePage p {
    font-size: 18px;
    margin-bottom: 25px;
    color: #000000;
  }
  
  .HomePage {
    /* Styles for the home page */
    text-align: center;
    padding: 50px 20px;
    color: rgba(14, 8, 8, 0.8);
  }

  .logo2 {
    height: 200px; /* Reduced height for a smaller logo */
    width: auto; /* Maintain aspect ratio */
    margin-top: -600px;
    position: absolute;
  }
  
  h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: rgba(31, 18, 1, 0.842);
    text-shadow: 0px 2.5px 1.5px rgba(131, 75, 1, 0.6);
    font-weight: bold;
  }
