.homePage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; /* This will keep the background fixed */
}
.gold-background {
  background-size: contain; /* Cover the entire area */
  background-repeat: no-repeat;
  background-position: relative;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #070707;
  color: #fff; /* Text color */
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(145deg, rgb(255, 187, 0) 10%, rgb(65, 53, 3) 20%, rgb(224, 200, 127) 40%, rgb(100, 82, 0) 60%, rgb(155, 108, 20) 80%, rgb(255, 155, 0) 100%);
  opacity: 0.98;
  background-size: 400% 400%;
  animation: gradientBG 10s ease infinite;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), inset 0 1px 3px 0 rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  border: 1px solid #c9b037;
  color: #070707; /* Dark text color for contrast */
  min-height: 1000px;
}

/* Animation for the gradient background */
@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.header {
  text-align: center;
  margin-bottom: 20px;
  color: #331d01;
  text-shadow: 0px 2.5px 1.5px rgba(131, 75, 1, 0.6);
  font-weight: bold;
}

h1 {
  font-size: 24px;
}


/* Connect Wallet button */
.daoConnect {
  position: relative;
  text-align: right;
  top: 20px;
  right: 0px;
  max-width: auto;
  max-height: 20px;
}
@media (max-width: 768px) {
  .daoConnect {
    text-align: right;
    text-wrap: wrap;
    top: 0px;
    right: -10px;

  
  }
}

.daoConnect button {
  position: relative;
  background-color: #08010179;
  color: #aa8303;
  padding: 0px 20px;
  border: 0.75mm solid #160f016e;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  top: 10px;
  right: 0px;
}

/* GPHX Balance section */
h2 {
  font-size: 18px;
  margin-top: 20px;
  color: #331d01;
  text-shadow: 0px 2px 1px rgba(131, 75, 1, 0.6);
}

/* Proposal Voting Section */
.proposals {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 768px) {
  .proposals {
    justify-content: center;
    align-items: center;
  }
}

/* Proposal card styles */
.proposalCard {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: #ffd900b0; /* Base gold color */
  background-image: linear-gradient(45deg, #725b08 5%, #caae0d 40%, #ffd000 60%, #eee092 100%);
  padding: 20px;
  border-radius: 6px; /* Slightly rounded corners */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3), /* Main shadow */
              inset 0 -1px 1px rgba(255, 255, 255, 0.3), /* Bottom highlight */
              inset 0 1px 1px rgba(0, 0, 0, 0.4); /* Top inner shadow for depth */
  margin-bottom: 20px; /* Space between proposal cards */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Ensure the shimmer effect doesn't go outside the card */
  max-width: 300px;
  min-height: 300px;
  font-weight: bold;
  color: #241b01c9;
  text-shadow: 0 5px 12px rgba(0, 0, 0, 0.3);

  /* Shimmer effect */
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%; /* Start from the left */
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.8) 50%, transparent 100%);
    transform: skewX(-20deg);
    transition: left 1.5s ease-in-out;
  }

  /* Animation for shimmer effect */
  &:hover:before {
    left: 150%; /* Move to the right */
  }
}

.proposalCard:hover {
  transform: translateY(-5px); /* Add a slight hover effect */
}

.proposalTitle {
  font-size: 18px;
  margin-bottom: 10px;
}

.proposalDescription {
  font-size: 14px;
  position: relative;
  height: 20px;
  font-weight: bold;
}

.timeRemaining {
  /* Styles for time remaining */
  font-size: 10px;
  color: #010300;
  padding: 50px 10px;
}

.proposalEnded {
  /* Styles for ended proposal message */
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  padding: 50px 10px;
}

.votes {
  /* General styles for the votes line */
  font-size: 14px;
  color: #000000;
  margin-left: 70px;
  position: flex;
}

.yesVotes {
  /* Styles for yes votes count */
  font-weight: bold;
  color: #2e9d2a;
}

.noVotes {
  /* Styles for no votes count */
  font-weight: bold;
  color: #e63946;
}

/* Unstake Tokens Section */
input[type="text"],
button {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 5px;
  font-size: 16px;
}

button {
  background-color: #ffd900b0; /* Base gold color */
  background-image: linear-gradient(45deg, #725b08 5%, #caae0d 40%, #b19412 60%, #eee092 100%);
  color: rgb(56, 39, 1)fff; /* Set text color to white */
  border: 1px solid #c9b037; /* Gold border */
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), /* Main shadow */
              inset 0 -1px 1px rgba(255, 255, 255, 0.3), /* Bottom highlight */
              inset 0 1px 1px rgba(0, 0, 0, 0.4); /* Top inner shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Sparkling effect on hover */
  &:hover {
    background-image: linear-gradient(45deg, #fffbd5, #ffdf00, #fffbd5);
    box-shadow: 0 0 15px #ffdf00; /* Brighter shadow to create a sparkle */
  }
}

button:active {
  background-color: #ffd700; /* Solid gold color for active state */
  box-shadow: 0 0 10px #ffdf00; /* Sparkle effect for active state */
} /* Darker blue on hover */


/* Admin Panel section (if applicable) */
.adminPanel {
  /* Add styles for admin panel components here */
}

.filterButtons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filterButton {
  background-color: #ffd900b0; /* Base gold color */
  background-image: linear-gradient(45deg, #725b08 5%, #caae0d 40%, #ffd000 60%, #eee092 100%);
  color: #ffbc04; /* Set text color to white */
  border: 1px solid #c9b037; /* Gold border */
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), /* Main shadow */
              inset 0 -1px 1px rgba(255, 255, 255, 0.3), /* Bottom highlight */
              inset 0 1px 1px rgba(0, 0, 0, 0.4); /* Top inner shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Sparkling effect on hover */
  &:hover {
    background-image: linear-gradient(45deg, #fffbd5, #ffdf00, #fffbd5);
    box-shadow: 0 0 15px #ffdf00; /* Brighter shadow to create a sparkle */
  }
}

.filterButton.active {
  background-color: #ffd700; /* Solid gold color for active state */
  box-shadow: 0 0 10px #ffdf00; /* Sparkle effect for active state */
}

.homePage {
  /* Styles for the background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* Ensure it covers the entire viewport or desired area */
  min-height: 100vh; 
  display: flex;
  align-items: center; /* Align content vertically */
  justify-content: center; /* Align content horizontally */
}

.contentContainer {
  text-align: center; /* Center the text and button */
  padding: 10px; /* Add some padding */
  background-color: rgba(206, 141, 2, 0.9); /* Optional: semi-transparent background for better readability */
  border-radius: 10px; /* Optional: rounded corners */
  /* Add more styling as needed */
}

.homePage h1 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 30px;
  color: #000000;
}

.homePage p {
  font-size: 18px;
  margin-bottom: 25px;
  color: #000000;
}

.homePage .daoLink {
  display: inline-block;
  background-color: #000000;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.homePage .daoLink:hover {
  background-color: rgb(252, 151, 0);
}

.homePage .chatLink {
  display: inline-block;
  background-color: #000000;
  color: #ffffff;
  padding: 15px 25px;
  border-radius: 8px;
  font-size: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.homePage .chatLink:hover {
  background-color: rgb(252, 151, 0);
}

.stakedTokensSection {
  /* Styles for the section */
}

.stakedTokensHeader {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space out the title and balance */
  margin-bottom: 10px; /* Add some margin below the header */
}

.stakedTokensBalance {
  margin-left: 20px; /* Space between the heading and balance */
  background-color: rgb(250, 180, 0); /* Optional background */
  padding: 5px 10px; /* Padding for the balance */
  border-radius: 5px; /* Rounded corners for the balance */
  color: #000000; /* Text color */
  font-weight: bold;
}

.HomePage {
  /* Styles for the home page */
  text-align: center;
  padding: 50px 20px;
  color: rgba(14, 8, 8, 0.8);
}
.logo {
  height: 100px; /* Reduced height for a smaller logo */
  width: auto; /* Maintain aspect ratio */
  margin-right: 0px;
  opacity: 0.8;
  position: relative;
  top: 30px;
}
@media (max-width: 768px) {
  .logo {
    height: 80px; /* Reduced height for a smaller logo */
    width: auto; /* Maintain aspect ratio */
    margin-right: 0px;
    opacity: 0.8;
    position: relative;
    top: 30px;
  }
}

h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: rgba(31, 18, 1, 0.842);
  text-shadow: 0px 2.5px 1.5px rgba(131, 75, 1, 0.6);
  font-weight: bold;
}
